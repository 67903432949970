import React, { useState, useEffect, useRef } from 'react';
import OrqueLogoFullBlack from '../assets/1.svg';
import OrqueLogoFullWhite from '../assets/2.svg';
import OrqueLogoBlack from '../assets/orque_logo_noir.svg';
import { DownloadCloud, Users, Rocket, FileText, ShoppingCart, Podcast, GraduationCap, Smartphone, PlaneIcon } from 'lucide-react'; 
import { Briefcase, Eye, Heart, Headphones, Info } from 'lucide-react';
import { FaFacebookF, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import axios from 'axios';
import {Play } from 'lucide-react';


// Images des mockups
import mockup1 from '../assets/mockup1.png'
import mockup2 from '../assets/mockup2.png'
import mockup3 from '../assets/mockup3.png'



// Si vous utilisez AOS pour les animations
import AOS from 'aos';
import 'aos/dist/aos.css';




const useOnScreen = (options) => {

  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const AnimatedNumber = ({ end, duration }) => {
  const [count, setCount] = useState(0);
  const [ref, isVisible] = useOnScreen({ threshold: 0.1 });
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (isVisible && !hasAnimated) {
      let startTime;
      const animateCount = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / duration, 1);
        setCount(Math.floor(end * percentage));
        if (percentage < 1) {
          requestAnimationFrame(animateCount);
        } else {
          setHasAnimated(true);
        }
      };
      requestAnimationFrame(animateCount);
    }
  }, [isVisible, end, duration, hasAnimated]);

  return <span ref={ref}>{count}</span>;
};

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);


  const [isAnimating, setIsAnimating] = useState(false);

  const toggleDarkMode = () => {
    setIsAnimating(true);
    setDarkMode(!darkMode);
    setTimeout(() => setIsAnimating(false), 300); // Duration of the animation
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
  
    setIsPlaying(true);
  };

  const handleClose = () => {

    setIsPlaying(false);
  };
  
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    
    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setStatus("Merci pour ton message!");
        form.reset();
      } else {
        const json = await response.json();
        if (json.errors) {
          setStatus(json.errors.map(error => error.message).join(", "));
        } else {
          setStatus("Oops! Il y a eu un problème lors de l'envoi");
        }
      }
    } catch (error) {
      setStatus("Oops! Il y a eu un problème lors de l'envoi");
    }
  };

  const [post, setPost] = useState(null);
    const [latestPodcast, setLatestPodcast] = useState({ title: '', description: '', image: '' });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://blog.orque.co/wp-json/wp/v2/posts?per_page=1&_embed')
          .then(response => response.json())
          .then(data => {
            // Assurez-vous que des données ont été récupérées
            if (data && data.length > 0) {
              setPost(data[0]);
            }
            setIsLoading(false); // Mettre fin au chargement une fois les données récupérées ou en cas d'erreur

          })
          .catch(error => {
            console.error('Error fetching data: ', error);
            setIsLoading(false); // Mettre fin au chargement en cas d'erreur

          });
      }, []);

      useEffect(() => {
        fetch('https://anchor.fm/s/ccc9c3b4/podcast/rss')
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                const items = data.querySelectorAll("item");
                const latestItem = items[0];
    
                const title = latestItem.querySelector("title").textContent;
                const pubDate = new Date(latestItem.querySelector("pubDate").textContent).toLocaleDateString('fr-FR');
                const imageNode = latestItem.querySelector("itunes\\:image, image");
                const image = imageNode ? imageNode.getAttribute("href") : '';
                const link = latestItem.querySelector("link").textContent;
    
                setLatestPodcast({ title, pubDate, image, link });
            })
            .catch(err => console.error(err));
    }, []);

    const [product, setProduct] = useState(null);
   

    const openProductPage = () => {
        if (product && product.permalink) {
            window.open(product.permalink, '_blank');
        }
    };
    

    useEffect(() => {
        const consumerKey = 'ck_3d3ac740452a7acce5202dff747dc7e58c4525f1';
        const consumerSecret = 'cs_c29d6bd242fccddda1adfa78479285d1b7a16db4';
        //last product // const url = `https://shop.orque.co/wp-json/wc/v3/products?per_page=1&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
        const productId = 4322; //shrimp 
        const url = `https://shop.orque.co/wp-json/wc/v3/products?include=${productId}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;


        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (data && data.length > 0) {
              setProduct(data[0]);
            }
            setIsLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data: ', error);
            setIsLoading(false);
          });
      }, []);

      const [latestVideo, setLatestVideo] = useState(null);
      useEffect(() => {
        const fetchLatestVideo = async () => {
          const API_KEY = 'AIzaSyChQCFiTfz3FJPmFjBTmD4fIpy40tkvcOk';
          const CHANNEL_ID = 'UCow29hHQsgq6jUa0YPhP_ww';
      
          try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
              params: {
                key: API_KEY,
                channelId: CHANNEL_ID,
                part: 'snippet',
                order: 'date',
                maxResults: 1,
                type: 'video'
              }
            });
      
            if (response.data.items.length > 0) {
              setLatestVideo(response.data.items[0]);
            }
          } catch (error) {
            console.error('Erreur lors de la récupération de la vidéo:', error);
          }
        };
      
        fetchLatestVideo();
      }, []);

      function decodeHTMLEntities(text) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
      }


      useEffect(() => {
        AOS.init({
          duration: 1000,
          once: true,
        });
      }, []);

      const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [mockup1, mockup2, mockup3];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);
      

  return (
    <section>
      <div className="p-4">
        <div className="grid grid-cols-1 gap-4 list-none lg:grid-cols-3 lg:grid-rows-3 xl:grid-cols-4 xl:grid-rows-4">
          {/* Carte de présentation */}
          <div className="lg:col-span-1 lg:row-span-1 ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8 relative">
            {/* Dark mode switch */}
            <div className="absolute top-7 left-7">
              <button
                onClick={toggleDarkMode}
                className={`w-14 h-7 flex items-center rounded-full p-1 ${
                  darkMode ? 'bg-gray-600' : 'bg-gray-300'
                } transition-colors duration-300 focus:outline-none`}
              >
                <div
                  className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
                    darkMode ? 'translate-x-7' : ''
                  } ${isAnimating ? 'animate-wiggle' : ''}`}
                />
                {/* Sun icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 text-primary absolute left-1.5 transition-opacity duration-300 ${
                    darkMode ? 'opacity-0' : 'opacity-100'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    clipRule="evenodd"
                  />
                </svg>
                {/* Moon icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 text-primary absolute right-1.5 transition-opacity duration-300 ${
                    darkMode ? 'opacity-100' : 'opacity-0'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                </svg>
              </button>
            </div>

            {/* Rest of the card content */}
            <p className="text-4xl font-orque tracking-tight font-medium text-primary dark:text-white md:text-6xl mt-8">
                Orque.
              </p>
            <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">La vérité est sous l'eau</p>
            
            <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400 font-light lg:text-xl">
              D’une vaine promesse d’enfant est née l’ambition d’une vie : 
            
              <p className="font-semibold">rendre la plongée sous-marine accessible à tous.</p>
                         
            </p>
            



  <p className="text-xl font-medium text-primary dark:text-white mt-4 mb-4">
    Suivez-nous
  </p>
  <div className="flex justify-center space-x-6">
        {/* Facebook */}
        <a
          href="https://www.facebook.com/OrqueFrance"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary dark:text-white transition-all duration-300 ease-in-out hover:scale-105"
        >
          <FaFacebookF className="w-8 h-8" />
        </a>

        {/* Instagram */}
        <a
          href="https://www.instagram.com/orquefrance"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary dark:text-white transition-all duration-300 ease-in-out hover:scale-105"
        >
          <FaInstagram className="w-8 h-8" />
        </a>

        {/* TikTok */}
        <a
          href="https://www.tiktok.com/@max.hiblot"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary dark:text-white transition-all duration-300 ease-in-out hover:scale-105"
        >
          <FaTiktok className="w-8 h-8" />
        </a>
         {/* YouTube */}
        <a
         href="https://www.youtube.com/@orquefrance"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary dark:text-white transition-all duration-300 ease-in-out hover:scale-105">
          <FaYoutube className="w-8 h-8" />
        </a>
      </div>
    </div>

      {/* Deuxième moitié de la carte de présentation */}
      <div className="ring-1 lg:row-start-2 h-full flex p-8 flex-col justify-center hover:ring-primary/5 dark:hover:ring-white/20 dark:ring-white/10 ring-primary/5 relative rounded-3xl overflow-hidden bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
      <a href="https://shop.orque.co " target="_blank" 
          rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
            <GraduationCap className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Cours
            </p>
          </a>
            <p className="mt-4 text-sm text-primary dark:text-zinc-300 font-light">
              ✅ Formations complètes 
            <br/>
              🎨 Supports illustré
              <br/>
              🎯 Quiz interactifs
              <br/>
              🖥️ Dématérialisés  
              <br/>          
              </p>
            
              <div className="flex-grow mt-4">
              {isLoading ? (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
              ) : post ? (
                <div className="flex flex-col">
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                    Dernière formation : 
                  </h4>
                  <a  href="https://shop.orque.co/produit/theorie-niveau-1" className="relative mb-4">
                    <img 
                      src="/images/ebook.PNG"
                      alt={post.title.rendered} 
                      className="rounded-xl object-cover w-full h-34"
                     
                    />
                  </a>
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                  Théorie Niveau 1
                  </h4>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
                    Découvrez le monde fascinant de la plongée sous-marine.
                  </p>
                  <a 
                    href="https://shop.orque.co/produit/theorie-niveau-1"
                    target="_blank" 
                    rel="noreferrer" 
                    className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                    >
                    Télécharger mon E-book
                  </a>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">
                  Erreur de chargement du post
                </div>
              )}
            </div>     
          </div>

    

          {/* Carte "blog" */}
          <div className="ring-1 dark:ring-white/10 ring-primary/5 rounded-3xl shadow-xl dark:shadow-thick flex flex-col p-8 h-full bg-white dark:bg-secondary">
          <a href="https://blog.orque.co " target="_blank" 
          rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
            <FileText className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Blog
            </p>
          </a>
            <p className="mt-4 text-sm text-primary dark:text-zinc-300 font-light">
              🏝️ Destinations
               <br/>
              📰  Actualités
              <br/>
              📚 Formations
              <br/>
              🐙 Monde marin  
              <br/>          
              </p>
            
              <div className="flex-grow mt-4">
              {isLoading ? (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
              ) : post ? (
                <div className="flex flex-col">
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                    Dernier article : 
                  </h4>
                  <div className="relative mb-4">
                    <img 
                      src={post._embedded['wp:featuredmedia'][0].source_url} 
                      alt={post.title.rendered} 
                      className="rounded-xl object-cover w-full h-48"
                    />
                  </div>
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                  {post.title.rendered}
                  </h4>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
                    {new Date(post.date).toLocaleDateString('fr-FR')}
                  </p>
                  <a 
                    href={post.link} 
                    target="_blank" 
                    rel="noreferrer" 
                    className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                    >
                    Lire plus
                  </a>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">
                  Erreur de chargement du post
                </div>
              )}
            </div>     
          </div>
              

          {/* Carte Geodiver */}
          <div className="ring-1 lg:row-span-2 flex flex-col group hover:ring-primary/10 dark:hover:ring-white/20 duration-300 h-full dark:ring-white/10 ring-primary/5 lg:row-start-2 md:grid-cols-2 md:grid lg:gap-0 md:items-start md:gap-12 lg:grid-cols-none lg:col-start-2 lg:col-span-2 rounded-3xl p-8 bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
      <div className="space-y-6">
        <div className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
          <Smartphone className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
          <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-4xl lg:text-5xl">
            GeoDiver
          </p>
        </div>
        
        <p className="mt-4 text-sm font-semibold text-zinc-500 dark:text-zinc-400 lg:text-xl">
          💡 Le compagnon de tous les plongeurs.
        </p>

        <div>
          <h3 className="text-base font-semibold text-primary dark:text-white mb-2">
            Vous êtes une organisation ?
          </h3>
          <ul className="space-y-1 text-sm text-zinc-500 dark:text-zinc-400">
            <li>📆 Créez des événements.</li>
            <li>👥 Gérez vos adhérents.</li>
            <li>⚙️ Pilotez vos activités.</li>
          </ul>
        </div>

        <div>
          <h3 className="text-base font-semibold text-primary dark:text-white mb-2">
            Tu es un plongeur ?
          </h3>
          <ul className="space-y-1 text-sm text-zinc-500 dark:text-zinc-400">
            <li>🔍 Trouve des spots de plongée.</li>
            <li>📆 Crée ou rejoins des sorties.</li>
            <li>🤝🏼 Participe à la vie de ton club.</li>
          </ul>
        </div>
      </div>

      <div className="relative mt-6 md:mt-0">
        <img 
          className="w-full aspect-[4/3] rounded-2xl flex items-center justify-center group overflow-hidden relative"
          src={images[currentImageIndex]}
          alt={`GeoDiver mockup ${currentImageIndex + 1}`}
        />
      </div>
    </div>
          
         
         
    

          {/* Carte "boutique"*/}
          <div className="ring-1 dark:ring-white/10 ring-primary/5 rounded-3xl shadow-xl dark:shadow-thick flex flex-col p-8 h-full bg-white dark:bg-secondary">
          <a href="https://shop.orque.co " target="_blank" 
          rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
            <ShoppingCart className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Shop
            </p>
          </a>
            <p className="mt-4 text-sm text-primary dark:text-zinc-300 font-light">
              🖼️ Tirages d’art
               <br/>
              📖 Livres 
              <br/>
              📱 E-books 
              <br/>
              🤿 Équipement de plongée  
              <br/>          
              </p>
            
              <div className="flex-grow mt-4">

              {isLoading ? (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
              ) : product ? (
                <div className="flex flex-col">
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                    Produit en vedette :
                  </h4>
                  <div className="relative mb-4">
                    <img
                      src={product.images[0].src}
                      alt={product.name}
                      className="rounded-xl object-cover w-full h-48"
                    />
                  </div>
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                    {product.name}
                  </h4>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
                    Prix: {product.price} €
                  </p>
                  <button
                    onClick={openProductPage}
                    className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                  >
                    Voir le produit
                  </button>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">
                  Erreur de chargement du produit
                </div>
              )}
            </div>
          </div>

          {/* Carte podcast */}
          <div className="ring-1 lg:row-start-3 h-full flex p-8 flex-col justify-center hover:ring-primary/5 dark:hover:ring-white/20 dark:ring-white/10 ring-primary/5 relative rounded-3xl overflow-hidden bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
          <a href="https://open.spotify.com/show/0tHLmnMIHiVgYks472BTaK?si=F_yYDiqiRVGv3SOw14w5MQ&nd=1&dlsi=d3ce339f810d4947" target="_blank" 
          rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
            <Podcast className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Podcast
            </p>
          </a>

            

          <div className="flex-grow mt-4">
          {isLoading ? (
    <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
  ) : latestPodcast ? (
    <div className="flex flex-col">
      <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
        Dernier podcast :
      </h4>
      <div className="relative mb-4">
        <img
          src={latestPodcast.image}
          alt={latestPodcast.title}
          className="rounded-xl object-cover w-full h-80"
        />
      </div>
      <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
        {latestPodcast.title}
      </h4>
      <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
        {latestPodcast.pubDate}
      </p>
      <a
        href={latestPodcast.link}
        target="_blank"
        rel="noreferrer"
        className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
      >
        Écouter
      </a>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full text-primary dark:text-white">
      Erreur de chargement du podcast
    </div>
  )}
            </div>   

        
    </div>

    


          {/* key number */}
          {/*<div className="-mr-24">
              <img
                src="/images/lexington.png"
                className="rounded-2xl object-cover ring-1 h-64 w-full lg:h-auto dark:ring-white/10 ring-primary/5 bg-tertiary"
                alt=""
              />
            </div> */}

<div className="col-span-1 sm:col-span-1 lg:col-span-1 xl:col-span-1 lg:order-7 xl:order-none ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8 relative flex flex-col justify-between">
<Info className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 " />


            <div className="grid grid-cols-1 gap-4">
              <div className="flex item-center">
                <Briefcase className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={6} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Projets</div>
                </div>
              </div>
              <div className="flex item-center">
                <Eye className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={259467} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Impressions</div>
                </div>
              </div>
              <div className="flex item-center">
                <Users className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={491} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Élèves</div>
                </div>
              </div>
              <div className="flex item-center">
                <Heart className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={35234} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">J'aime</div>
                </div>
              </div>
              <div className="flex item-center ">
                <Headphones className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={3675} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Heures d'écoute</div>
                </div>
              </div>
            </div>
              
             
            </div>
          
              {/*  portfolio */}
              <div className="col-span-1 sm:col-span-1 lg:col-span-1 xl:col-span-1 lg:order-8 xl:order-none ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8 relative">
              <a href="https://travel.orque.co " target="_blank" 
          rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
            <PlaneIcon className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Travel
            </p>
          </a>
            <p className="mt-4 text-sm text-primary dark:text-zinc-300 font-light">
              ✈️ Voyages plongée 
               <br/>
               🏝️ Présentations de destinationss
              <br/>
              🦭 Découverte de la vie marine
              <br/>
              🪴 Respect de l’environnement 
              <br/>          
              </p>
            
              <div className="flex-grow mt-4">
              {isLoading ? (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
              ) : post ? (
                <div className="flex flex-col">
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                    Dernier voyage : 
                  </h4>
                  <div className="relative mb-4">
                    <img 
                      src="/images/bali.PNG"
                      alt = "Plonger à Bali"
                      className="rounded-xl object-cover w-full h-34"
                    />
                  </div>
                  <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
                  Escapade à Bali (Indonésie)
                  </h4>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
                    Pour les plongeurs mais pas que ! 
                  </p>
                  <a 
                    href= "https://blog.orque.co/plonger-a-bali-indonesie/"
                    target="_blank" 
                    rel="noreferrer" 
                    className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                    >
                   Décourvir
                  </a>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full text-primary dark:text-white">
                  Erreur de chargement
                </div>
              )}
            </div>     
          </div>
        

         {/* Carte Contact */}
         <div className="ring-1 dark:ring-white/10 ring-primary/5 flex flex-col p-8 h-full justify-center items-center rounded-3xl overflow-hidden relative lg:col-span-2 lg:row-start-4 bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
          <div className="relative p-8 text-center w-full">
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Contactez <span className="lg:block">l'équipe</span>
            </p>
            <form 
              onSubmit={handleSubmit}
              action="https://formspree.io/f/xbljrpgp" 
              method="POST" 
              className="mt-6 w-full lg:max-w-sm mx-auto"
            >
              <div className="mb-4">
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input
                  type="email"
                  name="email"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="block w-full h-12 px-4 py-2 text-sm text-zinc-500 bg-zinc-100 dark:bg-tertiary ring-1 dark:ring-white/10 ring-primary/5 rounded-lg appearance-none focus:ring-white/20 placeholder-zinc-400 focus:border-zinc-300 focus:bg-primary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Votre email"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="sr-only">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  className="block w-full px-4 py-2 text-sm text-zinc-500 bg-zinc-100 dark:bg-tertiary ring-1 dark:ring-white/10 ring-primary/5 rounded-lg appearance-none focus:ring-white/20 placeholder-zinc-400 focus:border-zinc-300 focus:bg-primary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Votre message"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                >
                  Envoyer
                </button>
              </div>
            </form>
            {status && (
              <div className="mt-4 text-sm font-medium text-primary dark:text-white">
                {status}
              </div>
            )}
          </div>
        </div>

          {/* Carte Testimonials */}
          <div className="ring-1 dark:ring-white/10 ring-primary/5 shadow-xl dark:shadow-thick rounded-3xl p-8 lg:row-start-4 h-full flex flex-col justify-between bg-white dark:bg-secondary">
          {/* Carte YouTube */}
          <a href="https://www.youtube.com/@orquefrance" target="_blank" 
     rel="noreferrer" className="flex items-center transition-all duration-300 ease-in-out hover:scale-105">
    <FaYoutube className="text-primary dark:text-white h-5 w-5 md:h-12 md:w-12 mr-4" />
    <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
      YouTube
    </p>
  </a>
  <p className="mt-4 text-sm text-primary dark:text-zinc-300 font-light">
  🎥 Documentaires
 
 
             <br/>
🏝️ Destinations
              <br/>
              🤿 Tests matériel
              <br/>
              🎬 Vlog    
              <br/>          
              </p>
  
  <div className="flex-grow mt-4">
    {!latestVideo ? (
      <div className="flex justify-center items-center h-full text-primary dark:text-white">Chargement...</div>
    ) : (
      <div className="flex flex-col">
        <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
          Dernière vidéo :
        </h4>
        <div className="relative mb-4 aspect-video">
  <img
    src={latestVideo.snippet.thumbnails.high.url}
    alt={decodeHTMLEntities(latestVideo.snippet.title)}
    className="absolute inset-0 w-full h-full object-cover rounded-xl"
  />
  <a href={`https://www.youtube.com/watch?v=${latestVideo.id.videoId}`}
     className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out hover:scale-105">
    <div className="bg-black bg-opacity-50 rounded-full p-4">
      <Play className="text-white h-8 w-8" />
    </div>
  </a>
</div>
        <h4 className="text-lg font-medium text-primary dark:text-white mb-2">
        {decodeHTMLEntities(latestVideo.snippet.title)}
        </h4>
        <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-4">
          {new Date(latestVideo.snippet.publishedAt).toLocaleDateString()}
        </p>
        <a
          href={`https://www.youtube.com/watch?v=${latestVideo.id.videoId}`}
          target="_blank"
          rel="noreferrer"
          className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
        >
          Voir sur YouTube
        </a>
      </div>
    )}
  </div>
          </div>

          {/* Carte john et max */}
          <div className="lg:col-span-2 xl:col-span-1 h-full flex flex-col justify-between lg:row-span-2 ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8">
              
              <div className="flex items-center">
                <img src="/images/maxime-hiblot.jpg" alt="Maxime Hiblot" className="h-10 w-10 rounded-full mr-4" />
                <div>
                  <a
                    href="https://www.linkedin.com/in/maxime-hiblot"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-primary dark:text-white hover:underline"
                  >
                    Maxime Hiblot
                  </a>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">Co-Founder, 
                    <span className='font-orque'> Orque.</span>
                  </p>
                </div>
              </div>

            <p className="text-lg tracking-tight font-normal text-zinc-500 dark:text-zinc-400 md:text-xl">
            Je suis tombé amoureux de l’océan et de la plongée très jeune. Malheureusement, 
            j’ai découvert que la pratique était difficile d’accès et j’ai du attendre des années avant de vivre pleinement ma passion. 
            J’ai décidé de changer les choses en rendant la plongée accessible à tous. 
               <br />
              <br />
            </p>
            
              <div className="flex items-center">
                <img src="/images/john-pade.JPG" alt="John Pade" className="h-10 w-10 rounded-full mr-4" />
                <div>
                  <a
                    href="https://www.linkedin.com/in/john-padé"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-primary dark:text-white hover:underline"
                  >
                    John Pade
                  </a>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">Co-Founder, 
                    <span className='font-orque'> Orque.</span>
                  </p>                
                  </div>
              </div>

              <p className="text-lg tracking-tight font-normal text-zinc-500 dark:text-zinc-400 md:text-xl">
              Depuis toujours, j'ai été fasciné par les écosystèmes complexes des univers  aquatiques, une passion qui m'a mené à l'aquariophilie. Grâce à Maxime, j'ai découvert la plongée sous-marine, élargissant ainsi ma vision du bocal à l'immensité de la mer. 
              </p>
              <p className="text-lg tracking-tight font-normal text-zinc-500 dark:text-zinc-400 md:text-xl">

              Aujourd'hui, nous partageons nos voyages et avons décidé d'unir nos compétences pour contribuer à construire la plongée de demain.
              </p>

            <p className="text-xs text-zinc-400 dark:text-zinc-500 mt-4">© 2024 Tous droits réservés. Réalisé par John.</p>

          </div>


        </div>
      </div>
    </section>
  );
};

export default LandingPage;